import React from 'react';
import styled from "styled-components"; 

// <img class="" width="50" height="50" src="https://cryptologos.cc/logos/pancakeswap-cake-logo.png?v=010"></img> 

const Button = styled.button`
  color: palevioletred;
  font-size: 1em;
  font-bold;
  margin: 1em;
  padding: 0.25em 1em;
  border: 2px solid black;
  border-radius: 3px;
`;

const Card = styled.button`
  color: white;
  font-size: 1em;
  font-bold;
  margin: 1em;
  padding: 0.25em 1em;
  border: 2px solid black;
  border-radius: 3px;
`;


const TomatoButton = styled(Button)`
  color: black;
  border-color: black;
`;

const BlueButton = styled(Button)`
  color: black;
  border-color: black;
  background-color: blue;
`;


const HeaderGrid = () => {
    return(

            <div class="grid grid-cols-2 gap-2">
                <div class="flex flex-col my-36">
                    <p class="text-6xl font-bold mx-12">OwlPad</p>
                    <p class="text-3xl font-bold mx-14">NFT utitlity launchpad for the OwlProtocol</p>
                    <p class="text-xl mx-14 my-8">The NFT utility market is the next hottest thing in DeFi. Call dibs on the front seat</p>

                    <div class="flex flex-row gap-2 mx-8">                    
                        <TomatoButton  onClick={()=> alert("Coming Soon!")}>Buy HOO Token on Pancakeswap</TomatoButton> 
                        <TomatoButton  onClick={()=> alert("Coming Soon!")}>Charts</TomatoButton>
                        <BlueButton onClick={()=> alert("Coming Soon!")}>Launch App</BlueButton>
                    </div>
                </div>           
                <img class="my-14" src="https://secureservercdn.net/160.153.137.170/inh.2f8.myftpupload.com/wp-content/uploads/2020/09/WOO.png?time=1619998730"></img>
            </div>
    
    )
}


const TierGrid = () => {
    return(

            <div class="grid grid-cols-3 gap-2">
                <Card>
                    <p class="font-bold">Baby Owl</p>
                    <div  class="flex items-center justify-center" >
                    <img height="100" width="150" src="https://secureservercdn.net/160.153.137.170/inh.2f8.myftpupload.com/wp-content/uploads/2021/04/egg.png?time=1619998730"></img>
                    </div>
                    <p class="font-bold">200,000 HOO Tokens</p>
                    <p class="font-bold">Whitelist lottery</p>                    
                    <p class="font-bold">5% share of the pool</p>
                </Card>
                <Card>
                <p class="font-bold ">Teen Owl</p>
                    <div  class="flex items-center justify-center" >
                    <img height="100" width="150" src="https://secureservercdn.net/160.153.137.170/inh.2f8.myftpupload.com/wp-content/uploads/2021/04/egg.png?time=1619998730"></img>
                    </div>
                    <p class="font-bold">2,000,000 HOO Tokens</p>
                    <p class="font-bold">Whitelist lottery</p>                    
                    <p class="font-bold">15% share of the pool</p>
                </Card>
                <Card>
                    <div class="my-2">
                        <p class="font-bold">Based Owl</p>
                        <div  class="flex items-center justify-center" >
                            <img height="100" width="150" src="https://secureservercdn.net/160.153.137.170/inh.2f8.myftpupload.com/wp-content/uploads/2021/04/egg.png?time=1619998730"></img>
                        </div>
                        <p class="font-bold">200,000,000 HOO Tokens</p>
                        <p class="font-bold">Guaranteed investment</p>                    
                        <p class="font-bold">40% share of the pool</p>
                    </div>
                </Card>
            </div>
    
    )
}


const Examples = () => {
    return(
        <div>
            <div class="flex flex-col items-center justify-center my-8">
                <p class="mx-12 font-bold text-4xl ">Social Tokens</p>
                <p class="mx-12 w-1/2">Buy fractionalized tokens of your favorite content creator 
                and have a say in determining their worth, gain access to exclusive creator
                deals, trade their tokens on the open market</p>
            </div>

            <div class="flex flex-col items-center justify-center my-8">
                <p class="mx-12 font-bold text-4xl ">Saas burnout solutions</p>
                <p class="mx-12 my-2  w-1/2">Tired of having to be forced to get a bloomberg, techcrunch, 
                forbes or the verge account to read an article? By holding specific NFTs, you can gain access to all of these, 
                making life simpler, and more accessible to those around the world</p>
            </div>

            <div class="flex flex-col items-center justify-center my-8">
                <p class="mx-12 font-bold text-4xl">Games</p>
                <p class="mx-12 my-2 ">Building a cool NFT game? We'd love to hear your idea!</p>
            </div>


            <div class="flex flex-col items-center justify-center my-8">
                <p class="mx-12 font-bold text-4xl">NFT Marketplaces</p>
                <p class="mx-12 my-2  ">Solving access to NFTs is a prime issue worth solving</p>
            </div>

        </div>
    );
}


const Dashboard = () => {
    return(
        <div class="flex flex-col ">
            <HeaderGrid/>            
            <p class="flex justify-center font-bold text-3xl my-2">Launchpad Tiers</p>
            <TierGrid/>
            <p class="flex justify-center font-bold text-3xl my-2">There's a whole world of NFTy utility!</p>
            <Examples/>
        </div>
    );
}



export default Dashboard;