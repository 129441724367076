import React from 'react' 
import Presale from './pages/Presale'
import Dashboard from './pages/Dashboard'

function App(){
  return (
    <div class="bg-gradient-to-r from-green-300 via-blue-500 to-purple-600">
      <Dashboard/>
    </div>
  )
}

export default App;