import React, { useState, useEffect } from "react";
//import SimpleStorageContract from "./contracts/SimpleStorage.json";
import getWeb3 from "../getWeb3";
import MyToken from "../contracts/MyToken.json";
import MyTokenSale from "../contracts/MyTokenSale.json";
import KycContract from "../contracts/KycContract.json";

function Presale() {  

  const[storageValue, setStorageValue] = useState(0)
  const[web3, setWeb3] = useState(null)
  const[accounts, setAccounts] = useState(null)
  const[contract, setContract] = useState(null)
  const[loaded, setLoaded] = useState(false)
  const[kycAddress, setKycAddress] = useState('')
  const[myToken, setMyToken] = useState(null)
  const[myTokenSale, setMyTokenSale] = useState(null)
  const[kycContract, setKycContract] = useState('')
  const[tokenSaleAddress, setTokenSaleAddress] = useState('')
  const[userTokens, setUserTokens] = useState(0)
  const MY_TOKEN_ADDRESS="0x41b47648AdF76E068a89E3bc07C5C4fCC9e65Ddc"
  const MY_TOKEN_SALE_ADDRESS="0xC8C12710704Fe3574980793025D2569802081bea"
  const KYC_CONTRACT_ADDRESS="0xbD9b8852Dd3efbe2BD8Dd64fA08A3b2A38d51A0D"



  useEffect(() => {
      async function load(){
        try {
         // Get network provider and web3 instance.
         const web3 = await getWeb3();

         // Use web3 to get the user's accounts.
         const accounts = await web3.eth.getAccounts();
 
         // Get the contract instance.
         //this.networkId = await this.web3.eth.net.getId(); <<- this doesn't work with MetaMask anymore
         const networkId = await web3.eth.getChainId();     
         
         const myToken = new web3.eth.Contract(
           MyToken.abi,
           //MyToken.networks[networkId] && MyToken.networks[networkId].address,
           MY_TOKEN_ADDRESS
         );
 
         const myTokenSale = new web3.eth.Contract(
           MyTokenSale.abi,
           //MyTokenSale.networks[networkId] && MyTokenSale.networks[networkId].address,
           MY_TOKEN_SALE_ADDRESS
         );

         
         const kycContract = new web3.eth.Contract(
           KycContract.abi,
           //KycContract.networks[networkId] && KycContract.networks[networkId].address,
           KYC_CONTRACT_ADDRESS
         );

         setMyToken(myToken)
         setMyTokenSale(myTokenSale)
         setKycContract(kycContract);
         setWeb3(web3)
         setAccounts(accounts)
         setTokenSaleAddress(myTokenSale._address)
                
 
         // Set web3, accounts, and contract to the state, and then proceed with an
         // example of interacting with the contract's methods.
          setLoaded(true)
          //this.setState({ web3, accounts, contract: instance }, this.runExample);
          //runExample();
        } catch (error) {
          // Catch any errors for any of the above operations.
          alert(
            `Failed to load web3, accounts, or contract. Check console for details.`,
          );
          console.error(error);
        }
      }
      load();
      listenToTokenTransfer();
      updateUserTokens();
    }, [loaded]);

    const handleBuyToken = async () => {
      console.log("ACCOUNTS", accounts)
      await myTokenSale.methods.buyTokens(accounts[0]).send({from: accounts[0], value: 1});
    }

    const updateUserTokens = async() => {
      
      if(myToken){
        let userTokens = await myToken.methods.balanceOf(accounts[0]).call();      
        setUserTokens(userTokens)
      }      
    }
  
    const listenToTokenTransfer = async() => {
      if(myToken){
        myToken.events.Transfer({to: accounts[0]}).on("data", updateUserTokens);
        console.log("Tokens purchased")
      }
    }
  

    const handleInputChange = (event) => {
      const target = event.target;
      const value = target.type === "checkbox" ? target.checked : target.value;    
      console.log(value)  
      setKycAddress(value)
    }
    
    
    const handleKycSubmit = async () => {      
      await kycContract.methods.setKycCompleted(kycAddress).send({from: accounts[0]});
      alert("Account "+kycAddress+" is now whitelisted");
    }
    

    if (!web3 || !loaded) {
      return <div>Loading Web3, accounts, and contract...</div>;
    }

    return (

        <div className="App">
            <h1>Capuccino Token for StarDucks</h1>
            <h2>Enable your account</h2>
            Address to allow: <input type="text" name="kycAddress" value={kycAddress} onChange={handleInputChange} />
            <button type="button" onClick={handleKycSubmit}>Add Address to Whitelist</button>
            <h2>Buy Cappucino-Tokens</h2>
            <p>Send Ether to this address: {tokenSaleAddress}</p>
            <p>You have: {userTokens}</p>
            <button type="button" onClick={handleBuyToken}>Buy more tokens</button>
        </div>
      
      
    );
  
}

export default Presale;
